import { ClientTheme } from "./Client";
import { GulfTheme } from "./Gulf";
import { MovilizateTheme } from "./Movilizate";
import LogoGulf from "@npm-packages/assets/src/img/logo-gulf.png";
import LogoWizeo from "@npm-packages/assets/src/img/logo.png";
import LogoMovilizate from "@npm-packages/assets/src/img/logo-movilizate.png";
import bannerGulf from "@npm-packages/assets/src/img/banne-gulf-wizeo-light.jpg"; //'/assets/img/banne-gulf-wizeo-light.jpg';
import bannerWizeo from "../../assets/banner-wizeo.jpg"; //'/assets/img/banne-gulf-wizeo-light.jpg';

export const THEME_OPTION_CLIENT = "client";
export const THEME_OPTION_GULF = "gulf";
export const THEME_OPTION_MOVILIZATE = "movilizate";

export const ClientThemeOption = {
  title: "Wizeo",
  logo: {
    src: LogoWizeo,
    width: 130,
    widthLogin: 177,
  },
  banner: {
    src: bannerWizeo,
  },
  theme: { ...ClientTheme },
};
export const GulfThemeOption = {
  title: "Gulf",
  logo: {
    src: LogoGulf,
    width: 45,
    widthLogin: 100,
  },
  banner: {
    src: bannerGulf,
  },
  theme: { ...GulfTheme },
};
export const MovilizateThemeOption = {
  title: "Movilizate",
  logo: {
    src: LogoMovilizate,
    width: 130,
    widthLogin: 177,
  },
  banner: {
    src: LogoMovilizate,
  },
  theme: { ...MovilizateTheme },
};

export function getThemeOptionByEnv() {
  // return getThemeOption(window.REACT_APP_THEME_OPTION);
  return getThemeOption("movilizate");
}

export function getThemeOption(option) {
  // eslint-disable-next-line no-undef
  switch (option) {
    case THEME_OPTION_CLIENT:
      return ClientThemeOption;
    case THEME_OPTION_GULF:
      return GulfThemeOption;
    case THEME_OPTION_MOVILIZATE:
      return MovilizateThemeOption;
    default:
      return MovilizateThemeOption;
  }
}
