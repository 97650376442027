import React from "react";
import AppRoute from "./routes/AppRoute";
import GlobalContextProvider from "./components/Shared/GlobalContext/GlobalContextProvider";

export default function App() {
  return (
    <GlobalContextProvider>
      <AppRoute />
    </GlobalContextProvider>
  );
}
